@mixin cent {
    display: flex;
    justify-content: center;
    align-items: center;
}

#Services {
    width: 100%;
    height: auto;
    z-index: 1;
    background-color: #000;

    .Services-Header {
        width: 100%;
        height: 20em;
        @include cent();
        flex-direction: column;
        flex-wrap: wrap;
        color: var(--color0);

        .visualization-projects {
            font-family: 'Montserrat', sans-serif;
            font-size: 2em;
            font-weight: 900;
            width: 40%;
            padding-bottom: 1em;
            @include cent();

        }

        .visualization-projects-description {
            width: 40%;
            font-family: 'Montserrat', sans-serif;
            font-size: 1em;
            font-weight: 700;
            text-align: center;
        }
    }

    .one_service {
        width: calc(100% - 40vw);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        padding-left: 20vw;
        padding-right: 20vw;
        padding-top: 4em;
        background-color: #000;
        color: var(--color0);

        .the_title {
            font-family: 'Montserrat', sans-serif;
            font-size: 2.5em;
            font-weight: 900;
            @include cent();
            justify-content: flex-start;
            align-items: flex-end;
            padding-bottom: 1em;
            width: 55%;
            color: var(--color0);

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0%;
                width: 15em;
                height: .1em;
                background-color: var(--color0);
                transition-duration: .2s;
            }
        }

        .the_descrition {
            margin-top: 2em;
            width: 100%;
            font-family: 'Montserrat', sans-serif;
            font-size: .9em;
            font-weight: 700;
            @include cent();
            justify-content: flex-end;
            color: var(--color0);

            p {
                width: 100%;
                text-align: start;
            }
        }

        .project_container {
            margin-top: 3em;
            padding-bottom: 1em;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, auto);
            grid-gap: 1em;
            border-bottom: .01em solid rgba(255, 255, 255, 0.2);
            // border-top: .01em solid rgba(255, 255, 255, 0.2);

            .one_galerie_element_container {
                height: auto;

                .one_galerie_element {
                    width: 100%;
                    @include cent();
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr;
                    border-radius: 1.4em;
                    overflow: hidden;

                    img {
                        grid-column: 1 / 1;
                        grid-row: 1 / 1;
                    }

                    &:hover {
                        .one_galerie_element_hover {
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.7);

                            .iner_container {
                                top: 0%;
                            }
                        }
                    }

                    .one_galerie_element_hover {
                        grid-column: 1 / 1;
                        grid-row: 1 / 1;
                        width: 100%;
                        height: 0%;
                        overflow: hidden;
                        @include cent();
                        flex-wrap: wrap;
                        background-color: rgba(0, 0, 0, 0);
                        transition-duration: 0s, 1s;
                        transition-property: height, background-color;

                        .iner_container {
                            width: 100%;
                            height: auto;
                            @include cent();
                            flex-wrap: wrap;
                            top: 6%;
                            transition-duration: .3s;

                            .titre {
                                width: 99%;
                                min-height: 1em;
                                height: auto;
                                @include cent();
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 600;
                                font-size: 1.2em;
                                text-align: center;
                                color: var(--color0);
                                text-decoration: none;
                            }

                            .date {
                                width: 100%;
                                height: 1em;
                                @include cent();
                                font-family: 'Montserrat', sans-serif;
                                font-size: .7em;
                                font-weight: 100;
                            }
                        }
                    }
                }

                .one_galerie_link {
                    font-family: 'Montserrat', sans-serif;
                    font-size: .9em;
                    font-weight: 800;
                    width: auto;
                    height: 4em;
                    @include cent();
                    justify-content: flex-start;
                    margin-left: 1em;
                    transform: scale(1);
                    text-decoration: none;
                    color: var(--color0);
                    cursor: pointer;

                    ion-icon {
                        font-size: 1.2em;
                    }

                    &:hover {
                        transform: scale(1.01) translate(0%, -1%);
                    }

                    &:active {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

//!########################################

@media all and (max-width: 1000px) {

    #Services {

        .Services-Header {
            width: 100%;
            height: auto;
            @include cent();
            flex-direction: column;
            flex-wrap: wrap;

            .visualization-projects {
                font-family: 'Montserrat', sans-serif;
                font-size: 1.5em;
                font-weight: 900;
                width: 87%;
                padding-bottom: 1em;
                margin-top: 2em;
            }

            .visualization-projects-description {
                width: 87%;
                font-family: 'Montserrat', sans-serif;
                font-size: .8em;
                font-weight: 700;
                margin-bottom: 3em;
            }
        }

        .one_service {
            width: calc(100% - 4vw);
            padding-left: 2vw;
            padding-right: 2vw;

            .the_title {
                font-size: 1.4em;
                width: 87vw;

                &::after {
                    width: 50vw;
                }
            }

            .the_descrition {
                margin-top: 1em;
                font-size: .8em;

                p {
                    width: 100%;
                    text-align: start;
                }
            }

            .project_container {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(4, auto);


            }
        }
    }
}