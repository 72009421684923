@mixin cent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Hangar_1_container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, auto);
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 5em;

    .project_display {
        @include cent();
        flex-wrap: wrap;
        display: grid;
        grid-template-rows: repeat(10, auto);
        grid-template-columns: 1fr;
        padding-bottom: 2em;


        .project_title {
            font-family: 'Montserrat', sans-serif;
            font-size: 2em;
            font-weight: 800;
            margin-bottom: .2em;
            color: var(--color0);
        }

        .project_description {
            margin-bottom: 2em;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.2em;
            color: var(--color0);
            font-weight: 400;
        }

        .TheImageContainer {
            width: 60vw;
            height: auto;
            margin-bottom: 3em;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            img {
                transition-duration: .3s;
                transform: scale(1);
                border-radius: 1em;

                &:hover {
                    box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
                    transform: scale(1.03);
                }

            }
        }

        p {
            width: 60vw;
            margin-bottom: 2em;
            font-family: 'Montserrat', sans-serif;
            font-size: .8em;
            font-weight: 700;
            color: var(--color0);
        }
    }



}

//!########################################

@media all and (max-width: 1000px) {


    .Hangar_1_container {
        padding-left: 5vw;
        padding-right: 0vw;

        .project_display {


            .project_title {
                font-size: 1.5em;
            }

            .project_description {
                font-size: .7em;
            }

            .TheImageContainer {
                width: 90vw;
                margin-bottom: 1em;
            }

            p {
                width: 80vw;
            }
        }

    }

}