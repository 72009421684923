#Infos {
    width: 100%;
    height: auto;
    z-index: 1;

    .AccueilBigProjectsSection {
        width: 100%;
        min-height: 50em;


        .One_Project_In_Home {
            min-height: 57em;
            display: grid;
            grid-gap: .5em;

            .image_container {
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                transition-duration: .4s;
                border-radius: 1em;
                box-shadow: inset 0em -2em 250px #000;


                &:hover {
                    background-size: 105%;
                }
            }

            .info_container {
                width: 100%;
                height: 100%;
                grid-column: 1 / 1;
                grid-row: 1 / 1;
                @include cent();
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 40% 60%;
                // background-color: salmon;

                .title {
                    margin: auto;
                    margin-bottom: 0em;
                    @include cent();
                    justify-content: flex-start;
                    align-items: flex-end;
                    width: 80%;
                    height: auto;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 2em;
                    font-weight: 900;
                    text-decoration: none;


                }

                .description {
                    margin: auto;
                    margin-top: 4em;
                    width: 80%;
                    @include cent();
                    justify-content: flex-start;
                    align-items: flex-start;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1em;
                    font-weight: 700;
                    flex-wrap: wrap;

                    .the_link {
                        margin-top: 1em;
                        font-size: .81em;
                        text-decoration: none;
                        padding: .3em;
                        border-radius: .2em;
                        box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
                        border: .1em solid #86c5e5;

                        &:hover {
                            transform: scale(1.1);
                            background-color: #86c5e5;
                            box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
                        }

                        ion-icon {
                            bottom: -.2em;
                        }
                    }

                    .the_link:nth-child(2) {
                        margin-right: 2em;
                    }

                    .bar {
                        height: .15em;
                        width: 7em;
                        position: absolute;
                        top: -1em;
                        left: 0%;
                    }
                }

            }

        }

        .right {
            grid-template-columns: 30em repeat(2, 1fr);
            grid-template-rows: 1fr;

            .image_container {
                grid-column: 2 / 4;
                grid-row: 1 / 1;
            }

            .info_container {

                grid-column: 1 / 1;
                grid-row: 1 / 1;
            }

        }

        .left {
            grid-template-columns: repeat(2, 1fr) 30em;
            grid-template-rows: 1fr;

            .image_container {
                grid-column: 1 / 3;
                grid-row: 1 / 1;
            }

            .info_container {
                grid-column: 3 / 3;
                grid-row: 1 / 1;
            }
        }



    }
}







@media all and (max-width: 1000px) {
    #Infos {
        .Travaux-Header {
            width: 100%;
            height: auto;
            @include cent();
            flex-direction: column;
            flex-wrap: wrap;

            .visualization-projects {
                font-family: 'Montserrat', sans-serif;
                font-size: 1.5em;
                font-weight: 900;
                width: 87%;
                padding-bottom: 1em;
                margin-top: 2em;
            }

            .visualization-projects-description {
                width: 87%;
                font-family: 'Montserrat', sans-serif;
                font-size: .8em;
                font-weight: 700;
                margin-bottom: 3em;
            }
        }

        .AccueilBigProjectsSection {
            width: 100%;
            min-height: auto;

            .One_Project_In_Home {
                min-height: 35em;
                height: auto;
                grid-gap: 0em;
                margin-bottom: 2.5em;

                .image_container {
                    width: 100%;
                    background-position: center;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    box-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
                    border-radius: 0%;

                    &:hover {
                        background-size: 100%;
                    }

                }

                .info_container {

                    .title {
                        font-size: 1.3em;
                        width: 90%;
                        transform: scale(1);
                        margin-bottom: .5em;

                        .bar {
                            bottom: -0.3em;
                        }

                    }

                    .description {
                        margin-top: 1em;
                        font-size: .7em;
                        width: 90%;

                        .the_link {
                            font-size: 1.2em;
                        }
                    }

                }

            }

            .right {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);

                .image_container {
                    grid-column: 1 / 1;
                    grid-row: 1 / 1;
                }

                .info_container {
                    grid-column: 1 / 1;
                    grid-row: 2 / 2;
                }

            }

            .left {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);

                .image_container {
                    grid-column: 1 / 1;
                    grid-row: 1 / 1;
                }

                .info_container {

                    grid-column: 1 / 1;
                    grid-row: 2 / 2;
                }
            }
        }
    }
}