@mixin cent {
    display: flex;
    justify-content: center;
    align-items: center;
}

#Contacts {
    width: 100%;
    height: calc(100vh - 4em);
    z-index: 1;
    @include cent();
    flex-wrap: wrap;
    background-size: 100%;



    .follow_us {
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        font-size: 2em;
        font-weight: 800;
    }

    .Contact-container {
        width: 50em;
        height: 30em;
        @include cent();
        flex-direction: column;
        transform: scale(1.5);

        .One-Contact {
            height: 3.5em;
            overflow: hidden;
            width: 100%;
            cursor: pointer;
            position: relative;
            transition-property: top, opacity;
            text-decoration: none;

            &::after {
                content: '';
                position: absolute;
                top: 0%;
                left: 0%;
                background-color: var(--color0);
                width: 100%;
                height: 100%;
                opacity: 0;
            }

            .One-slider-Section {
                @include cent();
                width: 100%;
                flex-wrap: wrap;
                position: relative;
                top: -0%;
                transition-duration: .5s;

                .Top-Title {
                    width: 100%;
                    height: 3.5em;
                    @include cent();

                    .network {
                        width: 100%;
                        height: auto;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 3em;
                        cursor: pointer;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: 800;
                        color: var(--color0);
                        text-decoration: none;

                        &:hover {
                            transform: scale(1.1);
                            color: #000;
                        }

                        &:active {
                            transform: scale(0.9);
                        }

                        ion-icon {
                            font-size: .7em;
                        }
                    }
                }

                .bottom-Title {
                    width: 100%;
                    height: 3.5em;
                    @include cent();
                    opacity: .0;
                    transition-duration: .3s;
                    // border: .1em solid #86c5e5;

                    // &:hover {
                    //     transform: scale(1.1);
                    //     background-color: #86c5e5;
                    //     box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
                    // }




                    .network {
                        width: 100%;
                        height: auto;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 3em;
                        cursor: pointer;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: 800;
                        color: var(--color0);
                        text-decoration: none;

                        &:hover {
                            transform: scale(1.1);
                            color: #fff;
                        }

                        &:active {
                            transform: scale(0.9);
                        }

                        ion-icon {
                            font-size: .7em;
                        }

                    }

                    div {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 3.5em;
                        font-weight: 900;
                        color: #fff;
                    }
                }
            }
        }

    }
}


//!########################################################
@media all and (max-width: 1000px) {

    #Contacts {


        .Contact-container {
            // width: 50em;
            // height: 30em;
            // @include cent();
            // flex-direction: column;
            transform: scale(.7);


        }
    }
}