@mixin cent {
    display: flex;
    justify-content: center;
    align-items: center;
}

#Top-Bare {
    width: 100%;
    height: 4em;
    background-color: var(--colorX);
    position: absolute;
    z-index: 4000;
    @include cent;
    justify-content: flex-end;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);

    .logo-container {
        width: 4em;
        height: 100%;
        position: absolute;
        left: 4.5vw;
        top: 0em;
        background-image: url('./Images/SVG/Asset\ 6.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform: scale(3);
        cursor: pointer;
    }

    .page_title {
        height: 4em;
        width: auto;
        font-family: 'Montserrat', sans-serif;
        font-weight: 900;
        overflow: hidden;

        .page_title_slider {
            width: 100%;
            height: 500%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5, 1fr);
            transition-duration: 1s;
            color: #f1f1f1;

            .slides {
                @include cent();
            }
        }
    }

    .Menu-container {
        width: 3em;
        height: 100%;
        @include cent;
        cursor: pointer;
        margin-right: 1.5em;

        .Menu {
            width: 2.3em;
            height: 2.3em;
            border-radius: 50%;
            background-color: rgba($color: #f1f1f1, $alpha: 0);
            transition-duration: .2s;

            &::before {
                content: '';
                position: absolute;
                top: calc(40% - .1em);
                left: 25%;
                width: 50%;
                height: .15em;
                background-color: var(--color0);
                transition-duration: .2s;
            }

            &::after {
                content: '';
                position: absolute;
                top: calc(60% - .1em);
                left: 25%;
                width: 50%;
                height: .15em;
                background-color: var(--color0);
                transition-duration: .2s;
            }

            &:hover {
                background-color: rgba($color: #f1f1f1, $alpha: .1);
            }
        }

        .Menu_On {
            background-color: rgba($color: #f1f1f1, $alpha: 0);

            &::before {
                transform: rotate(-45deg);
                top: calc(50% - .1em)
            }

            &::after {
                transform: rotate(45deg);
                top: calc(50% - .1em);
            }

            &:hover {
                background-color: rgba($color: #f1f1f1, $alpha: .1);
            }
        }
    }

}

#Top-bare-slider-container {
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    height: calc(100vh - 4em);
    z-index: 3;
    opacity: .1;
    transition-duration: .5s, 1s;
    transition-property: top, opacity;
    @include cent();
    flex-direction: column;
    background-color: rgba(#000, .97);

    .Menu-container {
        width: 30em;
        height: 30em;
        @include cent();
        flex-direction: column;
        transform: scale(.8);

        .One-Section {
            height: 3.5em;
            overflow: hidden;
            width: 100%;
            cursor: pointer;
            position: relative;
            top: 40%;
            opacity: 0;
            transition-duration: .6s, 2s;
            transition-property: top, opacity;
            text-decoration: none;

            &::after {
                content: '';
                position: absolute;
                top: 0%;
                left: 0%;
                background-color: var(--colorX);
                width: 100%;
                height: 100%;
                opacity: 0;
            }

            .One-slider-Section {
                @include cent();
                width: 100%;
                flex-wrap: wrap;
                position: relative;
                top: -0%;
                transition-duration: .5s;
                color: #f1f1f1;

                .Top-Title {
                    width: 100%;
                    height: 3.5em;
                    @include cent();
                    color: #f1f1f1;

                    div {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 3.5em;
                        font-weight: 900;
                        color: #f1f1f1;
                    }
                }

                .bottom-Title {
                    width: 100%;
                    height: 3.5em;
                    @include cent();
                    opacity: .0;
                    transition-duration: .3s;

                    div {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 3.5em;
                        font-weight: 900;
                        color: #f1f1f1;
                    }
                }
            }
        }

    }

    .network_container {
        position: absolute;
        bottom: 2%;
        width: 10em;
        height: 2em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        z-index: 3;
        color: var(--colorX);

        a {
            color: var(--color0);

            &:hover {
                transform: scale(1.5);
                color: #000;
            }

            &:active {
                transform: scale(0.9);
            }

            .network {
                width: 1em;
                height: 1em;
                border-radius: 50%;
                text-align: center;
                font-size: 1.5em;
                cursor: pointer;
            }
        }
    }
}


@media all and (max-width: 1000px) {
    #Top-Bare {
        .logo-container {
            top: .5em;
            left: 10vw;
            margin-left: .5em;
            transform: scale(1.6);
            width: 6em;
            height: 3em;

        }

        .page_title {
            .page_title_slider {
                .slides {
                    font-size: .8em;
                }
            }
        }

        .Menu-container {
            margin-right: .5em;

            .Menu {
                transform: scale(1.1);

                &::before {
                    height: .12em;
                    top: calc(40% - .0em);
                }

                &::after {
                    top: calc(60% - .0em);
                    height: .12em;
                }

                &:hover {
                    background-color: rgba($color: #222, $alpha: 0);
                }
            }

            .Menu_On {
                &::before {
                    transform: rotate(-45deg);
                    top: calc(50% - .1em)
                }

                &::after {
                    transform: rotate(45deg);
                    top: calc(50% - .1em);
                }

                &:hover {
                    background-color: rgba($color: #222, $alpha: 0);
                }
            }

        }

    }
}