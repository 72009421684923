@mixin cent {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &::selection {
    color: var(--colorX);
    background: var(--color0);
  }
}

.App {
  width: 100vw;
  height: 100vh;
  background: var(--colorX);
  --color0: #f1f1f1;
  --colorX: #000;
  --color_bak: #fff;
  --color_tab_hover: #222;
  --shadow0: 0px 1px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  overflow-y: hidden;
  -webkit-tap-highlight-color: transparent;
  outline: none;


  .App_container {
    margin-top: 4em;
    width: 100%;
    height: calc(100% - 4em);
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    outline: none;


    .display_image_container {
      position: fixed;
      top: 4em;
      width: 100vw;
      min-height: calc(100vh - 4em);
      background-color: rgba(#000000, 0.9);
      @include cent();
      z-index: 110;

      div {
        width: 50%;
        background-color: var(--colorX);
        border: .5em solid var(--colorX);
        border-bottom: 1em solid var(--colorX);
        border-radius: .2em;

        .close_image_container {
          position: absolute;
          width: 3em;
          height: 3em;
          border-radius: 5%;
          background-color: rgba($color: rgb(0, 0, 0), $alpha: .5);
          transition-duration: .2s;
          z-index: 5;
          right: 0%;
          top: 0%;
          margin: .5em;
          border: 0em solid var(--colorX);
          transform: scale(1);

          &::before {
            content: '';
            position: absolute;
            transform: rotate(-45deg);
            top: calc(50% - .1em);
            left: 25%;
            width: 50%;
            height: .15em;
            background-color: var(--color0);
            transition-duration: .2s;

          }

          &::after {
            content: '';
            position: absolute;
            transform: rotate(45deg);
            top: calc(50% - .1em);
            left: 25%;
            width: 50%;
            height: .15em;
            background-color: var(--color0);
            transition-duration: .2s;

          }

          &:hover {
            background-color: rgba($color: rgb(0, 0, 0), $alpha: .5);
            transform: scale(1.2);
          }

          &:active {
            background-color: rgba($color: rgb(0, 0, 0), $alpha: .5);
            transform: scale(1);
          }
        }
      }

    }


    //!#####################################################################
    @media all and (max-width: 1000px) {

      //!############################################
      .display_image_container {
        top: 4em;
        min-height: calc(100vh - 4em);
        // min-width: calc(100vw - 1em);
        background-color: rgba(0, 0, 0, 0.9);

        div {
          width: 97%;
          border: .1em solid var(--colorX);
          border-bottom: .6em solid var(--colorX);

          .close_image_container {
            width: 3em;
            height: 3em;

            top: -18%;
            margin: -.1em;

          }
        }
      }
    }

    .before-loader {
      width: 100%;
      height: 100%;
      background-color: var(--colorX);
      position: fixed;
      z-index: 2;
      @include cent();
      display: none;

    }

    .hover_loader_container {
      width: 100%;
      height: 100%;
      background-color: var(--colorX);
      position: fixed;
      z-index: 2;
      @include cent();
      display: none;
    }

    .hover_loader {
      top: -4em;

      .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }

      .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: var(--color0);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }

      .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }

      .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }

      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }

        100% {
          transform: scale(1);
        }
      }

      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(0);
        }
      }

      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }

        100% {
          transform: translate(24px, 0);
        }
      }

    }

    @import "./TheFooter";

  }
}

// @import "./TheFooter";

// ################################################

@import "./TopBare";
// ################################################
@import "./Accueil";
// ################################################
@import "./Travaux";
// ################################################
@import "./Services";
// ################################################
@import "./Contacts";
// ################################################
@import "./Articles";
// ################################################
@import "./Interior_Design";
// ################################################
@import "./Small_House";
// ################################################
@import "./Hangar_1";
// ################################################
@import "./Touba";
// ################################################
@import "./ProduitsA";
// ################################################
@import "./Article_1";
// ################################################
@import "./Article_2";
// ################################################
@import "./Article_3";
// ################################################
@import "./Article_4";
// ################################################
@import "./Article_5";
// ################################################
@import "./Cité_1";
// ################################################
@import "./MSAD";
// ################################################
@import "./Villa_A";
// ################################################
@import "./Villa_B";
// ################################################
@import "./Villa_Teranga_Al_Amin";


//?#########################################################

//!#####################################################################