@mixin cent {
    display: flex;
    justify-content: center;
    align-items: center;
}

#Accueil {
    width: 100%;
    height: auto;
    z-index: 1;

    .Accueil_Slider-2 {
        width: 100%;
        height: 70vw;
        @include cent();
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: hidden;

        .thumbs {
            display: none;
        }

        .control-dots {
            z-index: 10;
            position: absolute;
            bottom: 8%;
            left: 50%;
            transform: translate(-50%);
            @include cent();
        }

        .carousel-status {
            display: none;
        }

        .sliders {
            background-position: inherit;
            background-size: 100%;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            @include cent();
            flex-direction: column;
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            transition-duration: 1s, 0s;
            transition-property: opacity, background-position-y;
            box-shadow: inset 0em -6em 150px #000;

            .sliders_inner_container {
                position: absolute;
                background-position: inherit;
                background-size: 100%;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
                @include cent();
                flex-direction: column;
                grid-column: 1 / 1;
                grid-row: 1 / 1;
                transition-duration: 1s, 0s;
                transition-property: opacity, background-position-y;
                box-shadow: inset 0em -6em 150px #000;

                // opacity: .5;
                .slider_title {
                    width: 55%;
                    font-size: 5em;
                    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
                    @include cent();
                    align-items: flex-start;
                    line-height: 1.2em;
                    flex-direction: column;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 900;
                    top: -6%;
                    color: white;

                    p {
                        text-align: left;
                    }
                }

                .slider_date {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.5em;
                    width: 55%;
                    color: var(--color0);
                    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
                    @include cent();
                    align-items: flex-start;
                    flex-direction: column;
                    font-weight: 300;
                    top: -6%;
                }
            }




            img {
                // display: none;
                width: 100%;
                height: 100%;
                // display: none;
                // position: absolute;
            }
        }

        .sliders:nth-child(1) {
            // background-image: url(./Images/Project/Intro-1.jpg);
            z-index: 3;
            opacity: 1;

            .sliders_inner_container {


                .slider_title:nth-child(1) {
                    color: var(--color0);
                }

                .slider_date {
                    color: var(--color0);
                }
            }

        }

        .sliders:nth-child(2) {

            // background-image: url(./Images/Project/Intro-2.jpg);
            .sliders_inner_container {
                .slider_title:nth-child(1) {
                    color: #f1f1f1;
                }

                .slider_date {
                    color: #f1f1f1;
                }
            }


        }

        .sliders:nth-child(3) {

            // background-image: url(./Images/Project/ProductA-1.jpg);
            .sliders_inner_container {
                .slider_title:nth-child(1) {
                    color: #cfab8b;
                }

                .slider_date {
                    color: #cfab8b;
                }
            }
        }

        .sliders:nth-child(4) {
            // background-image: url(./Images/Project/Villa_Teranga_Al_Amin-1.jpg);
        }

    }

    //!#####################################################################


    .archviz_presantation {
        height: 25em;
        width: 100%;
        @include cent();
        flex-wrap: wrap;
        color: var(--color0);

        .qui_somme_nous {
            width: 80%;
            font-family: 'Montserrat', sans-serif;
            font-weight: 900;
            font-size: 1.8em;
        }

        p {
            width: 50%;
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            text-align: center;
            font-size: 1.2em;
        }

        .network_container {
            font-size: .8em;
            width: 80%;
            @include cent();
            justify-content: flex-end;
            flex-direction: row;
            color: var(--colorX);

            a {
                color: var(--colorX);

                &:hover {
                    transform: scale(1.5);
                    color: #000;
                }

                &:active {
                    transform: scale(0.9);
                }

                .network {
                    width: 1.5em;
                    height: 1.5em;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 2em;
                    cursor: pointer;
                }
            }
        }

    }


    .intervention-area {
        width: 100vw;
        height: auto;
        @include cent();

        .the_link {
            text-decoration: none;

            .one-card {
                width: 18em;
                height: 36em;
                margin: 2vw 5vw 10vw 5vw;
                border: .15em var(--color0) solid;
                border-radius: 1em;
                overflow: hidden;
                @include cent();
                flex-wrap: wrap;
                align-items: start;
                transform: scale(1);
                transition-duration: .3s;
                filter: grayscale(100);
                cursor: pointer;

                &:hover {
                    transform: scale(1.07);
                    filter: grayscale(0);
                }

                p {
                    width: 90%;
                    font-family: 'Montserrat', sans-serif;
                    color: var(--color0);
                    text-align: center;
                    font-size: .7em;
                    font-weight: 700;
                }
            }
        }

        .card-cover {
            width: 100%;
            height: 65%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

        }

        .card-cover-1 {
            background-image: url('../Style/Images/Extra/automotive-showroom-7-1-2-1-768x536.jpg');
        }

        .card-cover-2 {
            background-image: url('../Style/Images/Extra/bewooden-1.jpg');
        }

        .card-cover-3 {
            background-image: url('../Style/Images/Extra/automotive-showroom-7-2.jpg');
        }

        .title-of-card {
            color: var(--color0);
            @include cent();
            font-family: 'Montserrat', sans-serif;
            font-size: 1.3em;
            font-weight: 900;
        }

    }

    .software {
        width: 100vw;
        height: 5em;
        margin: 20vw 0vw 20vw 0vw;
        @include cent();

        .unreal,
        .yord,
        .free,
        .czech,
        .meta,
        .nvidia,
        .Ue,
        .cutur,
        .isep,
        .arch {
            width: 4em;
            height: 4em;
            background-image: url('../Style/Images/SVG/unreal.png');
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            margin: 0vw 1vw 0vw 1vw;
        }

        .unreal {
            filter: invert(100);
        }

        .yord {
            background-image: url('../Style/Images/SVG/Yord.png');
            width: 8em;
        }
        .cutur {
            background-image: url('../Style/Images/SVG/Cultur.png');
            width: 12em;
            background-color: #f1f1f1;
        }
        
        .free {
            background-image: url('../Style/Images/SVG/free.png');
            width: 8em;
        }
        
        .czech {
            background-image: url('../Style/Images/SVG/Czech.png');
            width: 12em;
            background-color: #ffffff;
        }
        
        .meta {
            background-image: url('../Style/Images/SVG/Meta.png');
        }
        
        .nvidia {
            background-image: url('../Style/Images/SVG/Nvidia.png');
        }
        
        .Ue {
            background-image: url('../Style/Images/SVG/EU-EU-emblem-standard-version-positive-FR.png');
            width: 12em;
        }
        .arch {
            background-image: url('../Style/Images/SVG/Arch.png');
            width: 10em;
        }
        .isep {
            background-image: url('../Style/Images/SVG/isep.png');
            width: 6em;
        }

    }

    //!#####################################################################
    .AccueilBigProjectsSection {
        width: 100%;
        min-height: 50em;
        flex-wrap: wrap;

        .One_Project_In_Home {
            min-height: 55em;
            display: grid;
            grid-gap: .5em;
            margin-bottom: 1.5em;
            border: .5em solid black;

            .image_container {
                width: 100%;
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                transition-duration: .4s;
                border-radius: 1em;
                background-size: 105%;
                box-shadow: inset 0em -.51em 150px #000;

                &:hover {
                    background-size: 115%;
                }
            }

            .info_container {
                width: 100%;
                height: 100%;
                grid-column: 1 / 1;
                grid-row: 1 / 1;
                @include cent();
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 40% 60%;

                .title {
                    margin: auto;
                    @include cent();
                    justify-content: flex-start;
                    align-items: flex-end;
                    width: 80%;
                    height: 70%;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 2em;
                    font-weight: 900;
                    text-decoration: none;
                }

                .description {
                    margin: auto;
                    margin-top: 4em;
                    width: 80%;
                    @include cent();
                    justify-content: flex-start;
                    align-items: flex-start;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1em;
                    font-weight: 700;
                    flex-wrap: wrap;

                    .the_link {
                        margin-top: 1em;
                        font-size: .81em;
                        text-decoration: none;
                        padding: .3em;
                        border-radius: .2em;
                        box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
                        border: .1em solid #86c5e5;

                        &:hover {
                            transform: scale(1.1);
                            background-color: #86c5e5;
                            box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
                        }

                        ion-icon {
                            bottom: -.2em;
                        }
                    }

                    .the_link:nth-child(2) {
                        margin-right: 2em;
                    }

                    .bar {
                        height: .15em;
                        width: 8em;
                        position: absolute;
                        top: -1em;
                        left: 0%;
                    }
                }
            }

        }

        .right {
            grid-template-columns: 30em repeat(2, 1fr);
            grid-template-rows: 1fr;

            .image_container {
                grid-column: 2 / 4;
                grid-row: 1 / 1;
            }

            .info_container {
                grid-column: 1 / 1;
                grid-row: 1 / 1;
            }
        }

        .left {
            grid-template-columns: repeat(2, 1fr) 30em;
            grid-template-rows: 1fr;

            .image_container {
                grid-column: 1 / 3;
                grid-row: 1 / 1;
            }

            .info_container {
                grid-column: 3 / 3;
                grid-row: 1 / 1;
            }
        }

    }

    //!#####################################################################
    .galerie_presantation {
        height: 5em;
        width: 100%;
        @include cent();
        flex-wrap: wrap;
        margin-bottom: 5em;

        .nos_réalisations {
            width: auto;
            padding: .4em;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: .8em;
            text-decoration: none;
            color: var(--color0);
            border-radius: .3em;
            box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
            border: .1em solid #86c5e5;

            &:hover {
                transform: scale(1.1);
                background-color: #86c5e5;
                box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
            }

            ion-icon {
                bottom: -.2em;
            }
        }

        .galerie_container {
            width: 100%;
            padding-left: 10vw;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 2em
        }
    }

    //!#####################################################################
    .equipe_presantation {
        height: auto;
        width: 100%;
        @include cent();
        flex-wrap: wrap;
        color: var(--color0);

        .equipe {
            width: 80%;
            font-family: 'Montserrat', sans-serif;
            font-weight: 900;
            font-size: 1.8em;
        }

        .equipe_big_container {
            width: 100%;
            @include cent();
            flex-wrap: wrap;

            .members {
                width: 15em;
                height: auto;
                margin: 3em;
                @include cent();
                flex-wrap: wrap;

                .pic {
                    width: 7em;
                    height: 7em;
                    background-image: url('./Images/Extra/Profil-3.jpg');
                    background-size: 110%;
                    border-radius: 50%;
                    background-position: center;
                    margin-bottom: 1em;
                }

                .pic1 {
                    background-image: url('./Images/Extra/Profil-4.jpg');
                }

                .pic2 {
                    background-image: url('./Images/Extra/Profil-2.jpg');
                }

                .pic4 {
                    background-image: url('./Images/Extra/Profil-1.jpg');
                }

                em {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 900;
                    font-size: 1em;
                    width: 100%;
                    text-align: center;
                }

                p {
                    text-align: center;


                    font-family: 'Montserrat',
                        sans-serif;
                    margin-top: 1em;
                    font-size: .7em;
                    font-weight: 200;
                }
            }
        }

    }


    //!#####################################################################
}

//!############################################
@media all and (max-width: 1000px) {
    #Accueil {
        .Accueil_Slider-2 {
            // height: auto;
            overflow: hidden;
            height: 60vh;
            // background-color: #86c5e5;

            .control-dots {
                .dot {
                    transform: scale(1.3);
                }
            }

            .sliders {
                background-size: 100%;
                background-size: cover;
                background-position-x: 30%;
                transition-duration: 0s, 0s;
                transition-property: opacity, background-position-y;
                // background-color: #cfab8b;
                height: 65vh;
                overflow: hidden;

                img {
                    object-fit: cover;
                }

                .sliders_inner_container {
                    height: 100%;
                    overflow: hidden;


                    .slider_title {
                        width: 80%;
                        font-size: 1.5em;
                        margin-left: 0%;
                        opacity: .9;
                    }

                    .slider_date {
                        width: 80%;
                        font-size: .9em;
                        margin-left: 0vw;
                    }
                }

            }

            .sliders:nth-child(1) {

                // background-position-x: 60%;
                img {
                    // object-fit: cover;
                    // left: -23%;
                    // background-position-x: 60%;

                }

                .sliders_inner_container {

                    .slider_title:nth-child(1) {
                        color: var(--color0);
                        opacity: .9;
                    }
                }
            }
        }

        //!#####################################################################
        .archviz_presantation {
            .qui_somme_nous {
                width: 100%;
                @include cent();
                font-size: 1.5em;
            }

            p {
                width: 80%;
                font-size: 1em;
            }

            .network_container {
                justify-content: center;
            }

        }


        .intervention-area {
            @include cent();
            flex-wrap: wrap;

            .one-card {
                width: 80%;
                height: 30em;

                &:hover {
                    transform: scale(1.03)
                }
            }

            .card-cover {}

            .card-cover-1 {}

            .card-cover-2 {}

            .card-cover-3 {}

            .title-of-card {}

        }


        .software {
            @include cent();
            flex-wrap: wrap;
            transform: scale(.8);
            height: 15em;
            margin-bottom:14em ;
            
            .unreal,
            .yord,
            .free,
            .czech,
            .meta,
            .nvidia {
                margin: 1em;
            }
        }

        //!#####################################################################
        .AccueilBigProjectsSection {
            width: 100%;
            min-height: auto;

            .One_Project_In_Home {
                min-height: 30em;
                height: auto;
                grid-gap: 0em;

                .image_container {
                    width: 100%;
                    background-position: center;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    box-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);

                    &:hover {
                        background-size: 100%;
                    }
                }

                .info_container {


                    .title {
                        font-size: 1.3em;
                        width: 90%;
                        transform: scale(1);

                        .bar {
                            bottom: -0.3em;
                        }
                    }

                    .description {
                        margin-top: 1em;
                        font-size: .7em;
                        width: 90%;

                        .the_link {
                            font-size: 1.2em;
                            box-shadow: #86c5e5 0 0 5px, inset #86c5e5 0 0 5px;
                            border: .1em solid #86c5e5;
                        }
                    }
                }
            }

            .right {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);

                .image_container {
                    grid-column: 1 / 1;
                    grid-row: 1 / 1;
                }

                .info_container {
                    grid-column: 1 / 1;
                    grid-row: 2 / 2;
                }
            }

            .left {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);

                .image_container {
                    grid-column: 1 / 1;
                    grid-row: 1 / 1;
                }

                .info_container {
                    grid-column: 1 / 1;
                    grid-row: 2 / 2;
                }
            }
        }

        //!#####################################################################
        .galerie_presantation {
            height: 10em;

            .nos_réalisations {
                padding-left: 1em;
                font-size: .8em;
            }

            .galerie_container {
                font-size: 1.3em
            }
        }

        //!#####################################################################
        .the_galerie_container {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(25, 1fr);

            .one_galerie_element {
                &:hover {
                    img {
                        transform: scale(1);
                    }
                }
            }
        }

        //!#####################################################################
        .equipe_presantation {
            text-align: center;

            .equipe {
                width: 100%;
                @include cent();
                font-size: 1.3em;
            }

            .equipe_big_container {

                .members {}
            }
        }
    }
}