   .the_footer {
       width: calc(97% - 10em);
       height: 40em;
       display: grid;
       grid-template-columns: repeat(4, 1fr);
       grid-template-rows: repeat(13, 1fr);
       padding-left: 5em;
       padding-right: 5em;
       padding-top: 2em;
       background-color: rgba(0, 0, 0, 1);
       font-family: 'Montserrat', sans-serif;
       font-size: 1em;
       font-weight: 800;
       color: var(--color0);
       margin: auto;
       margin-top: 2em;
       margin-bottom: 2em;
       border-radius: 1em;

       .logo-container {
           width: 5em;
           height: 5.5em;
           margin-left: 10%;
           //    background-image: url('./Images/SVG/Asset\ 6.svg');
           background-position: center;
           background-repeat: no-repeat;
           background-size: 90%;
           filter: invert(100%);
           transform: scale(2);
       }

       .archviz_description {
           margin-left: 10%;
           margin-top: 1.5em;
           font-size: .7em;
           color: rgb(104, 104, 104);
       }

       .titles {
           @include cent();
           justify-content: flex-start;
           align-items: flex-end;
           margin-left: 20%;
       }

       .titles:nth-child(4) {
           grid-column: 4;
           grid-row: 2;
           display: none;
       }

       .titles:nth-child(5) {
           grid-column: 4;
           grid-row: 1;
       }

       .portfolio_link {
           @include cent();
           justify-content: flex-start;
           align-items: flex-end;
           margin-left: 20%;
           grid-column: 4;
           grid-row: 3;
           font-size: .7em;
           color: red;

           ion-icon {
               font-size: 1.2em;
           }
       }

       .menu_elemant_container {
           height: 100%;
           grid-column: 1 / 2;
           grid-row: 3 / 8;
           margin-left: 10%;
           @include cent();
           justify-content: flex-start;
           align-items: flex-start;
           flex-direction: column;

           .menu_elemant {
               height: calc(100% * 5);
               @include cent();
               justify-content: flex-start;
               align-items: flex-end;
               font-size: .7em;
               color: rgb(104, 104, 104);
               text-decoration: none;

               &:hover {
                   transform: scale(1.1);
               }
           }
       }

       .categories_elemant_container {
           height: 100%;
           grid-column: 2 / 2;
           grid-row: 3 / 9;
           margin-left: 20%;
           @include cent();
           justify-content: flex-start;
           align-items: flex-start;
           flex-direction: column;

           ion-icon {
               top: -.1em;
               transform: scale(.8);
           }

           .categories_elemant {
               height: calc(100% * 6);
               @include cent();
               justify-content: flex-start;
               align-items: flex-end;
               font-size: .7em;
               color: rgb(104, 104, 104);
           }
       }

       .contact_elemant_container {
           grid-column: 3 / 3;
           grid-row: 3 / 5;
           margin-left: 20%;
           @include cent();
           justify-content: flex-start;
           align-items: flex-start;
           flex-direction: column;

           .contact_elemant {
               height: calc(100% * 2);
               @include cent();
               justify-content: flex-start;
               align-items: flex-end;
               font-size: .7em;
               color: rgb(104, 104, 104);
           }
       }

       .network_container {
           font-size: .7em;
           @include cent();
           justify-content: flex-end;
           align-items: flex-start;
           flex-direction: row;
           color: var(--colorX);
           grid-column: 3;
           grid-row: 5 / 9;
           margin-left: 20%;
           flex-direction: column;

           a {
               height: calc(100% / 4);
               color: var(--color0);
               @include cent();
               align-items: flex-end;

               ion-icon {
                   bottom: -.3em;
               }

               &:hover {
                   transform: scale(1.1);
                   color: var(--color0);
               }

               &:active {
                   transform: scale(0.9);
               }

               .network {
                   width: 1.5em;
                   text-align: start;
                   font-size: 2em;
                   cursor: pointer;
               }
           }

       }

       .the_line {
           width: 100%;
           height: .1em;
           background-color: rgb(104, 104, 104);
           grid-column: 1 / 5;
           grid-row: 12 / 12;
           opacity: .5;
       }

       .country {
           grid-column: 4 / 5;
           grid-row: 12 / 13;
           font-size: .7em;
           color: rgb(104, 104, 104);
           @include cent();
           justify-content: flex-end;
           font-weight: 500;
       }

       .rights {
           grid-column: 1 / 3;
           grid-row: 12 / 13;
           font-size: .7em;
           color: rgb(104, 104, 104);
           @include cent();
           font-weight: 500;
           justify-content: flex-start;
       }
   }


   //!##########################################################
   @media all and (max-width: 1000px) {
       .the_footer {
           width: calc(100%);
           height: 70em;

           grid-template-rows: repeat(23, 1fr);
           padding-left: 0em;
           padding-right: 0em;
           padding-top: 1em;
           margin: 0;
           border-radius: 0em;

           .logo-container {
               width: 5em;
               height: 5em;
               margin-left: 4vh;
           }

           .archviz_description {
               margin-top: 1.5em;
               grid-column: 1 / 4;
               grid-row: 2 / 2;
               margin-left: 4vh;
           }

           .titles {
               margin-left: 4vh;
           }

           .titles:nth-child(2) {
               grid-column: 1 / 4;
               grid-row: 8 / 8;
           }

           .titles:nth-child(3) {
               grid-column: 1 / 4;
               grid-row: 16 / 16;
           }

           .titles:nth-child(4) {
               grid-column: 1 / 4;
               grid-row: 20 / 20;
               display: none;
           }

           .titles:nth-child(5) {
               grid-column: 3 / 5;
               grid-row: 6;
               margin-left: 10%;
           }

           .portfolio_link {
               margin-left: 10%;
               grid-column: 3 /5;
               grid-row: 7;
           }

           .menu_elemant_container {
               grid-column: 1 / 4;
               margin-left: 4vh;
               font-size: 1.2em;
           }

           .categories_elemant_container {
               margin-left: 4vh;
               grid-column: 1 / 4;
               grid-row: 9 / 15;
               font-size: 1.2em;
           }

           .contact_elemant_container {
               grid-column: 1 / 3;
               grid-row: 17 / 19;
               margin-left: 4vh;
               font-size: 1.2em;
               transform: translateY(3%);
           }

           .network_container {
               font-size: 1em;
               justify-content: center;
               align-items: center;
               grid-column: 1 / 5;
               grid-row: 20 / 22;
               margin-left: 0%;
               flex-direction: row;
           }

           .the_line {
               grid-column: 1 / 5;
               grid-row: 17 / 17;
               display: none;
           }

           .country {
               grid-column: 1 / 5;
               grid-row: 23 / 23;
               justify-content: center;
               margin: 0;
           }

           .rights {
               grid-column: 1 / 5;
               grid-row: 22 / 22;
               justify-content: center;
               margin: 0;
           }
       }
   }